<template>
  <div class="search-area">
    <input
      class="search-box"
      type="text"
      :value="searchQuery"
      @input="updateSearchQuery"
      placeholder="キーワードを入力してください"
    />
  </div>
</template>
  
<script>
export default {
  props: {
    modelValue: String,
  },
  data() {
    return {
      searchQuery: this.modelValue,
    };
  },
  methods: {
    updateSearchQuery(event) {
      this.searchQuery = event.target.value;
      this.$emit("update:modelValue", this.searchQuery);
    },
  },
};
</script>

<style scoped>
.search-area {
  margin-top: 30px;
  text-align: center;
}

.search-box {
  width: 70%;
  height: 2rem;
  font-size: 1rem;
  max-width: 500px;
  padding: 10px;
  border: solid 1px #2ecc71;
}

.search-box:focus {
  outline: none;
}

@media screen and (max-width: 480px) {
  .search-box {
    width: 80%;
    height: 1rem;
  }
}
</style>
  