<template>
  <div class="game-card">
    <img
      :class="['game-img', gameData.purchaseFlag ? '' : 'game-img-filter']"
      :src="gameData.image"
      :title="gameData.title"
      @click="
        gameData.purchaseFlag && gameData.review ? openModal(gameData) : null
      "
    />
    <div class="game-title" @click="copyText(gameData.title)">
      {{ gameData.title }}
    </div>
    <div
      :class="[
        'game-info-box',
        'game-status-color-' +
          (gameData.purchaseFlag && gameData.review ? 'on' : 'off'),
      ]"
      @click="
        gameData.purchaseFlag && gameData.review ? openModal(gameData) : null
      "
    >
      <div class="left-box">
        <div class="game-info">
          {{
            gameData.releaseDate === "9999-99-99"
              ? "未定"
              : gameData.releaseDate
          }}
        </div>
        <div class="game-info">
          {{ gameData.packageFlag ? "パッケージ版" : "ダウンロード版" }}
        </div>
        <div class="game-info">
          {{
            gameData.review
              ? "評価完了"
              : gameData.purchaseFlag
              ? "評価中"
              : "未購入"
          }}
        </div>
      </div>
      <div :class="['game-rank-' + (gameData.review ? 'on' : 'off')]">
        {{ gameData.rank }}
      </div>
    </div>
  </div>
</template>

<script>
import { useCopyStore } from "../stores/copyStore";
import { useModalStore } from "../stores/modalStore"; // modalStoreをインポート

export default {
  props: {
    gameData: Object, // ゲーム情報を受け取るプロパティ
  },
  emits: ["open-modal"], // open-modalイベントを定義
  setup() {
    const copyStore = useCopyStore();
    const modalStore = useModalStore(); // modalStoreを使用
    const openModal = (game) => {
      modalStore.openModal(game); // モーダルを開く
    };

    return {
      copyText: copyStore.copyText,
      openModal,
    };
  },
};
</script>

<style scoped>
.game-card {
  width: 240px;
  text-align: center;
  margin-top: 20px;
  position: relative;
}

.game-title {
  color: #3c3c3c;
  font-weight: bold;
  font-size: 1.1em;
  overflow: hidden;
  flex-grow: 1;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 40px;
  margin-top: 10px;
}

.game-img {
  max-width: 240px;
  height: 150px;
}

.game-img-filter {
  filter: grayscale(1);
}

.game-info-box {
  margin-top: 5px;
  text-align: left;
  padding-left: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.left-box {
  flex: 1;
  width: 50%;
}

.game-status-color-on {
  border-left: 5px solid #2ecc71;
}

.game-status-color-off {
  border-left: 5px solid #8c8c8c;
}

.game-info {
  font-size: 1rem;
  color: #8c8c8c;
  font-weight: bold;
}

.game-rank-on,
.game-rank-off {
  text-align: center;
  text-align: left;
  font-family: "rank-font", sans-serif;
  font-display: auto;
  font-size: 3rem;
  width: 50px;
}

.game-rank-on {
  color: #2ecc71;
}

.game-rank-off {
  color: #8c8c8c;
}

@media screen and (max-width: 480px) {
  .game-card {
    width: 160px;
  }

  .game-title {
    font-size: 1rem;
  }

  .game-img {
    max-width: 160px;
    max-height: 100px;
  }
}
</style>
