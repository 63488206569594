<template>
  <div class="platform-list">
    <!-- プラットフォームごとのタイトルを作成する -->
    <div v-for="platformNo in platformNoList" :key="platformNo">
      <PlatformName :platformNo="platformNo" />
      <div class="game-list">
        <!-- 各プラットフォームごとのゲームを格納する -->
        <GameCard
          v-for="(gameData, gameIndex) in platformData[platformNo]"
          :key="gameIndex"
          :gameData="gameData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PlatformName from "./PlatformName.vue";
import GameCard from "./GameCard.vue";

export default {
  props: {
    filteredGames: Array, // フィルタリングされたゲーム情報
  },
  data() {
    return {
      // プラットフォーム別のデータを格納するオブジェクト
      platformData: {},
    };
  },
  computed: {
    platformNoList() {
      // プラットフォームNoの配列を取得する
      return Object.keys(this.platformData).map(Number);
    },
  },
  watch: {
    filteredGames: {
      immediate: true,
      handler: function (newFilteredGames) {
        // フィルタリングされたゲーム情報からプラットフォームデータを作成する
        const newPlatformData = {};
        newFilteredGames.forEach((gameData) => {
          const platformNo = gameData.platformNo;
          if (!newPlatformData[platformNo]) {
            newPlatformData[platformNo] = [];
          }
          newPlatformData[platformNo].push(gameData);
        });
        this.platformData = newPlatformData;
      },
    },
  },
  components: {
    PlatformName,
    GameCard,
  },
};
</script>

<style scoped>
.platform-list {
  margin: 80px auto;
  max-width: 1400px;
  width: 95%;
}

.game-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  justify-content: center;
  justify-items: center;
  gap: 20px;
}

@media (max-width: 800px) {
  .game-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 360px) {
  .game-list {
    grid-template-columns: repeat(1, 240px);
  }
}
</style>
  