<template>
  <div class="filter-area">
    <label
      :class="{
        'filter-button': true,
        'filter-button-active': selectedPlatforms.includes('1'),
      }"
    >
      <input
        type="checkbox"
        value="1"
        v-model="selectedPlatforms"
        @change="emitPlatform"
      />
      購入済
    </label>
    <label
      :class="{
        'filter-button': true,
        'filter-button-active': selectedPlatforms.includes('0'),
      }"
    >
      <input
        type="checkbox"
        value="0"
        v-model="selectedPlatforms"
        @change="emitPlatform"
      />
      未購入
    </label>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      selectedPlatforms: ["0", "1"], // 初期状態で両方のチェックボックスを選択
    };
  },
  methods: {
    emitPlatform() {
      const hasPlatform0 = this.selectedPlatforms.includes("0");
      const hasPlatform1 = this.selectedPlatforms.includes("1");
      if (hasPlatform0 && hasPlatform1) {
        // 両方が選択されている場合
        this.$emit("platformSelected", "all");
      } else if (hasPlatform0 || hasPlatform1) {
        // どちらか一方が選択されている場合
        this.$emit("platformSelected", this.selectedPlatforms);
      } else {
        // どちらも選択されていない場合
        this.$emit("platformSelected", "none");
      }
    },
  },
  mounted() {
    this.emitPlatform();
  },
};
</script>

<style scoped>
.filter-area {
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.filter-button {
  display: inline-block;
  padding: 13px 50px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #8c8c8c;
  background-color: transparent;
  border: 2px solid #2ecc71;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
}

.filter-button-active {
  background-color: #2ecc71;
  color: white;
}

.filter-button input[type="checkbox"] {
  display: none;
}

@media screen and (max-width: 480px) {
  .filter-area {
    margin-top: 20px;
    width: 80%;
  }

  .filter-button {
    padding: 10px 30px;
    font-size: 1rem;
  }
}
</style>