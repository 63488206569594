<template>
  <div class="purchase-flag-count">
    <div class="status-count">
      <div class="status-label">購入済</div>
      <div class="status-value">{{ purchasedCount }}</div>
    </div>
    <div class="status-count">
      <div class="status-label">未購入</div>
      <div class="status-value">{{ notPurchasedCount }}</div>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    games: Array, // ゲーム情報の配列を受け取るプロパティ
  },
  computed: {
    notPurchasedCount() {
      return this.games.filter((game) => !game.purchaseFlag).length;
    },
    purchasedCount() {
      return this.games.filter((game) => game.purchaseFlag).length;
    },
  },
};
</script>

<style scoped>
.purchase-flag-count {
  margin: 10px auto;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.status-count {
  font-size: 1.5rem;
  text-align: center;
  padding: 3px;
}

.status-label {
  margin: 20px;
}
</style>
