import { defineStore } from "pinia";

export const useCopyStore = defineStore("copy", {
  state: () => ({
    copiedText: "",
    showNotification: false,
  }),
  actions: {
    copyText(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.copiedText = text;
          this.showNotification = true;
          setTimeout(() => {
            this.showNotification = false;
          }, 1000);
        })
        .catch((err) => {
          console.error("コピーに失敗しました。:", err);
        });
    },
  },
});
