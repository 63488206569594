import { defineStore } from "pinia";

export const useModalStore = defineStore("modal", {
  state: () => ({
    showModal: false, // モーダルの表示状態
    selectedGame: null, // 選択されたゲームデータ
  }),
  actions: {
    openModal(game) {
      this.selectedGame = game; // 選択されたゲームデータをセット
      this.showModal = true; // モーダルを表示
    },
    closeModal() {
      this.showModal = false; // モーダルを非表示
      this.selectedGame = null; // 選択されたゲームデータをリセット
    },
  },
});
