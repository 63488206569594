<template>
  <section>
    <img class="platform-icon" :src="platform?.platformImage" />
    <div class="border-line"></div>
  </section>
</template>

<script>
export default {
  props: {
    platformNo: Number,
  },
  data() {
    return {
      platforms: [],
    };
  },
  computed: {
    platform() {
      // platformNoに対応するプラットフォーム名を取得する
      const platform = this.platforms.find(
        (p) => p.platformNo === this.platformNo
      );
      return platform;
    },
  },
  created() {
    // プラットフォームデータを読み込む
    fetch("./json/platformData.json")
      .then((response) => response.json())
      .then((data) => {
        this.platforms = data;
      })
      .catch((error) => {
        console.error("プラットフォームデータの読み込みに失敗しました:", error);
      });
  },
};
</script>

<style scoped>
section {
  transform: scale(0.9);
  animation: fade-in 1s ease-out forwards;
  margin: 100px 0 10px 0;
  text-align: center;
}

.platform-icon {
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-bottom: 20px;
}

@media screen and (max-width: 480px) {
  section {
    margin: 30px 0 10px 0;
  }
}
</style>
