<template>
  <div
    v-if="modalStore.showModal"
    class="modal-overlay"
    @click.self="closeModal"
  >
    <div class="modal-content slide-in">
      <div class="modal-body">
        <!-- 左側 -->
        <div class="game-left">
          <img
            :src="modalStore.selectedGame.image"
            alt="Game Image"
            class="game-img"
          />
          <div class="game-title">
            {{ modalStore.selectedGame.title }}
          </div>
          <div class="game-release-date">
            {{
              modalStore.selectedGame.releaseDate === "9999-99-99"
                ? "未定"
                : modalStore.selectedGame.releaseDate
            }}
          </div>
        </div>
        <!-- 右側 -->
        <div class="game-right">
          <div class="evaluation">総評</div>
          <div class="game-rank">
            {{
              modalStore.selectedGame.rank ? modalStore.selectedGame.rank : "?"
            }}
          </div>
          <div class="game-review">
            <span
              v-html="
                modalStore.selectedGame.review
                  .replace(/。/g, '。<br>')
                  .replace(/#/g, '<br>')
              "
            ></span>
          </div>
        </div>
      </div>
      <button class="close-button" @click="closeModal">閉じる</button>
    </div>
  </div>
</template>

<script>
import { useModalStore } from "../stores/modalStore"; // modalStoreをインポート

export default {
  emits: ["close"], // closeイベントを定義
  setup() {
    const modalStore = useModalStore(); // modalStoreを使用
    const closeModal = () => {
      modalStore.closeModal(); // モーダルを閉じる
    };

    return {
      modalStore,
      closeModal,
    };
  },
};
</script>

<style scoped>
.slide-in {
  animation: slide-in-from-bottom 0.5s ease forwards;
}

@keyframes slide-in-from-bottom {
  from {
    transform: translateY(100vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 50px 10px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.modal-overlay.closing {
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.5s ease;
}

.modal-overlay.closing .modal-content {
  transform: translateY(100vh);
  opacity: 0;
}

.modal-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.game-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 20px;
  width: 300px;
  max-width: 50%;
}

.game-img {
  margin-bottom: 10px;
}

.game-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 10px;
}

.game-release-date {
  font-size: 1rem;
  color: gray;
  margin-top: 5px;
}

.game-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  max-width: 50%;
}

.evaluation {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.game-rank {
  font-family: "rank-font", sans-serif;
  font-display: auto;
  font-size: 3rem;
  color: #2ecc71;
  margin-bottom: 15px;
}

.game-review {
  font-size: 1.1rem;
  line-height: 1.5;
}

.close-button {
  margin-top: 50px;
  padding: 10px 20px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #c0392b;
}

@keyframes slide-in-from-bottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 800px) {
  .modal-content {
    padding: 30px;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .game-right {
    text-align: center;
    max-width: 100%;
  }
  .game-left {
    margin-bottom: 30px;
  }
}
</style>